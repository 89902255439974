<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
             <SideMenu :indexsec="8"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
           
            <form
              @submit.prevent="
                submit(
                 data.title,
                 file1,
                 date_form,
                )
              "
            >
            <div class="div_EProduct">
              <p>EDITAR GALERÍA</p>
              </div>

  <a class="btn-ver" :href="'/galeria/'+data.url" target="_blank"> VER GALERÍA <img src="../../assets/Arrow_6.png" alt=""> </a>
  <div class="mtrow"></div>

              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelN color-1" for="inputN">Título</label>
                  <b-form-input
                  pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    v-model="data.title"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>

                <div class="div-image QUITLEFT">
                  <label class="labelImg color-1" for="inputImg">Imagen Portada</label>
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div>

                 
                
              </div>

                <div class="row mx-0 row-two">
                 
                    <div class="EPF1_C1">
                  <label class="labelA color-1" for="inputA"
                    >Fecha  <span class="f_actual">actual: {{data.dateimg}}</span></label
                  >
                  <b-form-input
                    v-model="date_form"
                    id="inputA"
                    type="date"
                   />
                  </div>
                  <div id="edit-user-img" class="Imagen_Edit2" v-if="msgimg == 'success'">
                 <img :src="this.image(data.image)"  alt="imagen de usuario">
                 </div>
                </div>


           
               <div v-if="this.section==''" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>


              

              

              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">La galeria se edito correctamente</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="agregarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
              
              
              
                 


                 <hr class="mtrow">
                  

                  <!--{{images_gallery}} -->
              <form
              @submit.prevent="
                agregarImagen(
                 file2,
                )
              "
            >
              <div class="row mx-0 row-one">
               
                <div class="div-image ">
                  <label class="labelImg2 color-1" for="inputImg2">Imagenes</label>
                  <b-form-file
                    v-model="file2"
                    id="inputImg2"
                    type="file"
                    name="inputImg2"
                    multiple="multiple"
                    :placeholder="'Subir imagen'"
                  />
                </div>

                 
                
              </div>


              <div class="btn-form-2">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >AGREGAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">La imagen se guardo correctamente</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="agregarOtro()"
                      >AÑADIR OTRA</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
              
            
            
           
               <div v-if="this.section=='imagenes'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>





              <div class="images_gallery Div_ImagesCenter">
                    <div class="row gallery_titles">
                        <div class=" gt1">
                          <p>Visualización</p>
                        </div>
                        <div class=" gt2">
                          <p>Eliminar</p>
                        </div>
                    </div>
                 
                  <div v-if="images_gallery != 'No se encontraron coincidencias'">
                  <div  v-for="(item,key) in images_gallery" class="item_gallery" :key="key">
                        <div class="row">
                            <div class="gt1">
                              <p> <img :src="urlpath.url()+'/get-imagesgallery-image/'+item.image" alt=""></p>
                            
                            </div>
                            <div class="gt2">
                              <p class="eliminar-gallery" @click="eliminarImagen(item.id)">&#10006;</p>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div v-else>
                        <div class="mtrow msg_channels">
                            <p>Esta galleria aun no tiene imagenes.</p>
                        </div>
                  </div>
                   
                    
           </div>


<hr class="mtrow">
                  <label id="Label_AgrCanales" class="labelpr color-1" >Agregar Categorías</label>

                  <div v-if="this.section =='categorias'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>
              </div>
                
                  <form
              @submit.prevent="
                agregarCategoria(
                  category_form
                )
              "
            >
              
              


              <div class="row mx-0 row-two">
               
                <div class="div_Canal">
                    <label id="label_subtitle" class="labelEq color-1" >Categoría</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{category_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.categories" :key="key">
                          <b-dropdown-item @click="changeCategory(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>

              </div>

             


             
              <div id="EditPartbtn_Agregar" class="btn-form-2">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal"
                  type="submit"
                  >AGREGAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{message_modal}}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="wait()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
         
           <div class="canales">
                    <div id="div_canales" class="row channel_titles">
                       <div>
                          <p>ID</p>
                        </div>
                        <div>
                          <p>Nombre</p>
                        </div>
                       
                        <div>
                          <p>Eliminar</p>
                        </div>
                    </div>
                 
                  <div v-if="data.categories != 'No se encontraron coincidencias'">
                  <div  v-for="(item,key) in data.categories" class="item_channel" :key="key">
                        <div class="row">
                          <div>
                         <p>{{item.id}}</p>
                          </div>
                            <div>
                              <p>{{item.category_name}} </p>
                            </div>
                           
                            <div>
                              <p class="eliminar-channel" @click="eliminarCategoria(item.id)">&#10006;</p>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div v-else>
                        <div class="mtrow msg_channels">
                            <p>Esta galería aun no tiene categorias asignadas.</p>
                        </div>
                  </div>
                   
                    
           </div>













 <!--- Modal de errores  --->
          <b-modal
            id="modal-error"
            ref="modal-error"
            no-close-on-backdrop
            centered
            hide-footer
            hide-header
          >
            <div class="message-modal">
              <img :src="closeIcon" class="icon3 pt-5"><br>
              <p class="text-modal">{{msg}}</p>
            </div>
            <div class="">
              <b-button class="btn-modal" @click="wait()"
                >Aceptar</b-button
              >
              <b-button
                type="button"
                aria-label="Close"
                class="close-modal"
                @click="wait()"
                >×</b-button
              >
            </div>
          </b-modal>
          <!---  --->
  
          
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import Header from '../../components/Header';
import { mapActions } from "vuex";
import i_logout from '../../assets/iconocerrar.png';
let urlpath = require('../../global/url')
export default {
  name: "EditGallery",
    components: {
    Header,
    SideMenu,
  },
  data() {
    return {
      date_form:'',
      status: "",
      msg: "",
      msgimg: "success",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      closeIcon: i_logout,
      file2:null,
      urlpath,
      section:'',
      category_form :'',
      category_name: 'categoría',
      message_modal:'',

    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
    let id = this.$route.params.id;
    let payload = {
      id: id,
      option: "gallery",
    };
    this.getInfoById(payload);

    let payload2= {
      id: id,
      option: "images_gallery/gallery",
    };
    this.getImagesGalleryById(payload2);
    
  },
  computed:{
      data() {
      return this.$store.getters["main/data"];
    },
     url() {
      return this.$store.getters["main/baseURL"];
    },
    images_gallery(){
        return this.$store.getters["images_gallery/data"];  
    },
    user() {            
        return this.$store.getters['admin/getIdentity'];
    },
    categories() {
       let allctg =this.$store.getters["categories/data"];
       let result =[]
       let model;
        for(var i =0; i<allctg.length; i++){
         if(allctg[i].parent_name != 'TIENDA' && allctg[i].name != 'TIENDA' && allctg[i].name != 'ROPA' && allctg[i].parent_name != 'ROPA'){ //Mostrar solo categorias para ropa.
           if(allctg[i].id== 1){
             model ={
                        id: allctg[i].id,
                        id_parent: allctg[i].id_parent,
                        name: 'NINGUNA',
                        parent_name: allctg[i].parent_name
                      }
          }else{
            model ={
            id: allctg[i].id,
            id_parent: allctg[i].id_parent,
            name: allctg[i].name,
            parent_name: allctg[i].parent_name
            }
          }

           result.push(model)
         }
          
         
       }

      return result
    },
  },
  methods: {
    ...mapActions("main", ["getInfoById"]),
    ...mapActions("images_gallery", ["getImagesGalleryById"]),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },

     wait2: function () {
      setTimeout(() => this.$router.go(), 1500);
    },
    showModalError() {
            this.$refs["modal-error"].show();
         },
    eliminarImagen: async function(id){
      
      let option ='images_gallery'
      let result = await this.$store.dispatch("main/deleteItem", {option: option, id:id});
              
      if(result.status=='error'){
        this.section='imagenes'
        this.msg = result.message;
        this.showModalError();
      }else{
        this.section==''
        this.msg='';
        this.wait()
      }
    },
    agregarImagen: async function(files){
      let id_gallery = this.$route.params.id;
          this.status=''
          this.msg=''
      if(files  == null){
            this.section='imagenes'
            this.status='error'
            this.msg='No has seleccionado ninguna imagen.'
            return null
      }
       

       if(files.length ==0 ){
            this.section='imagenes'
            this.status='error'
            this.msg='Sin imagenes seleccionadas.'
            return null
       }else{
       let results =[]
         for( const image of files){

           if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{

           

           let splitname = image.name.split('.')
           let lenext = splitname.length -1
           let ext = splitname[lenext]
           
            ext = ext.toLowerCase();
           if(ext=='png' || ext =='jpg' || ext =='jpeg' || ext =='gif'){
                var data = new  FormData();
        
            data.append('image', image);
            data.append('id_gallery', id_gallery);
            data.append('_method', 'POST');
          
                
            this.status=''
            this.msg=''
        
            let result = await this.$store.dispatch("main/addItem",  {option:'images_gallery', item: data});
            results.push(result)
           }else{
              results.push({status:'error'})
           }

            }

           
           
         }
            
            
            let sts = 'success'
            for(var i=0; i<results.length; i++){
                    if(results[i].status =='error'){
                      sts ='error'
                    }
            }

          

            
             if(sts=='error'){
                 this.section='imagenes'
              this.status='error'
              this.msg= 'Ha ocurrido un error con alguna de las imagenes, asegurate de que la extension sea png , jpg o jpeg.'
              this.wait2();
            }else{ // success
                this.content=''
                this.showModal() 
            }
            
       
        
                

        
       }

    },
    submit: async function (  title, image, date) {
          let id = this.$route.params.id;
           let gallery = this.data
          this.status=''
          this.msg=''

        if(date==''){
          date = gallery.dateimg
        }

    

         if(image == null){
                 let data ={
                     id:id,
                     title:title,
                     dateimg:date
                 }
                  this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/editItem",  {option:'gallery', item: data});
                
                  if(result.status=='error'){
                    this.status='error'
                    this.msg= result.message
                  }else{ // success
                      this.content=''
                      this.showModal() 
                  }
           
        }else{

          if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{
                var data = new  FormData();
              
                  data.append('id',id);
                  data.append('image', image);
                  data.append('title',title);
                  data.append('dateimg', date);
                  data.append('_method', 'PUT');
                
                      
                  this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/editItem",  {option:'gallery', item: data});
                
                  if(result.status=='error'){
                    this.status='error'
                    this.msg= result.message
                  }else{ // success
                      this.content=''
                      this.showModal() 
                  }

            }
                

        }

    },
    agregarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
    eliminarCategoria: async function(id){
      let option ='gallery/category'
      let result = await this.$store.dispatch("main/deleteItem", {option: option, id:id});
              
      if(result.status=='error'){
        this.section='categorias'
        this.msg = result.message;
        this.showModalError();
      }else{
        this.section==''
        this.msg='';
        this.wait()
      }
    },
    agregarCategoria: async function(id_category){
        let id_gallery = this.$route.params.id;
       this.message_modal ='La categoria se agrego correctamente.'

        /*
        console.log(id_blog)
        console.log(id_category)*/

        if(id_category == '' || id_category == undefined || id_category==null){
                this.section='categorias'
               this.status='error'
              this.msg='Elige una categoría.'
        }else{

          id_gallery= String(id_gallery)
          id_category = String(id_category)
          let data={
            "id_category":id_category,
            "id_gallery":id_gallery
          }

          this.status=''
          this.msg=''
       
          let result = await this.$store.dispatch("main/addItem",  {option:'gallery/category', item: data});
        
          if(result.status=='error'){
            this.section='categorias'
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal() 
          }
        }
    },

      changeCategory: function (value, name) {
     
       this.category_form = value;
       this.category_name = name;

      },
  image: function(img){
        let src = this.url +'/get-gallery-image/'+img;
         this.status_img(img)
        return src
         },
    status_img:async  function (img){
    let userimg =''
      try{
          userimg = await this.$store.dispatch("main/getImage",{image: img, option:'gallery'})
          if(userimg.data.status=='error'){
              this.msgimg='error'
          }else{
              this.msgimg='success'
          }
      }catch(err){
          this.msgimg='error'
      }
    },

  },
};
</script>
